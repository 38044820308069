import Rails from '@rails/ujs'
export async function rails_fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  if (!init) {
    init = {}
  }
  if (!init.headers) {
    init.headers = new Headers()
  }

  let type = contentType(init.headers, init.body)

  if (init.headers instanceof Headers) {
    if (type) {
      init.headers.append('Content-Type', type)
    }
    init.headers.append('Accept', 'application/json')
    init.headers.append('X-CSRF-Token', Rails.csrfToken())
  } else if (init.headers instanceof Array) {
    if (type) {
      init.headers.push(['Content-Type', type])
    }
    init.headers.push(['Accept', 'application/json'])
    init.headers.push(['X-CSRF-Token', Rails.csrfToken()])
  } else {
    if (type) {
      init.headers['Content-Type'] = type
    }
    init.headers['Accept'] = 'application/json'
    init.headers['X-CSRF-Token'] = Rails.csrfToken()
  }
  return fetch(input, init)
}

function contentType(headers, body) {
  if (headers['Content-Type']) {
    return headers['Content-Type']
  } else if (body == null || body instanceof window.FormData) {
    return undefined
  } else if (body instanceof window.File) {
    return body.type
  }

  return 'application/json'
}
