import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect(): void {
    ;(window as any).Luca.Controllers['FlashList'] = this
  }

  add(flash: string) {
    // This is not a standard way of doing stimulus, the data target in this case is outside the controller,
    // but this lets us add a place to put the flashes completely free
    // It is used by split view to ensure the flash is only on the left side and can be used other places as well
    // if necessary
    let element = document.querySelector('[data-flash-list-target="alternativeFlashElement"]')

    if (element == null) {
      // This is the real default
      element = this.element
    }
    element.insertAdjacentHTML('afterbegin', flash)
  }
}
